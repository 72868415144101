import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {sorter} from "@shared/utils/sorters";
import {EntitiesSelectors} from "@utils/features/types";

const placesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const placesSlice = createSlice({
  name: "places",
  initialState: placesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: placesAdapter.reducers,
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await loadListFromBackend(
      "places",
      projectId,
      state.places.init,
      () => dispatch(placesActions.initContext(projectId)),
      (data) => dispatch(placesActions.initList({list: data, project: projectId}))
    );
  },
};

const placesAdapterSelectors = placesAdapter.getSelectors((state) => state.places);

export const placesSelectors: EntitiesSelectors<any, any> = {
  selectList: placesAdapterSelectors.selectAll,
  selectById: placesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.places.init.status === "loaded",
};

export const placesReducer = placesSlice.reducer;

export const placesActions = {
  ...placesSlice.actions,
  ...asyncActions,
};
