import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {sorter} from "@shared/utils/sorters";
import {EntitiesSelectors} from "@utils/features/types";

const stewardsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.lastName, b.lastName),
});

export const stewardsSlice = createSlice({
  name: "stewards",
  initialState: stewardsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: stewardsAdapter.reducers,
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await loadListFromBackend(
      "stewards",
      projectId,
      state.stewards.init,
      () => dispatch(stewardsActions.initContext(projectId)),
      (data) => dispatch(stewardsActions.initList({list: data, project: projectId}))
    );
  },
};

const stewardsAdapterSelectors = stewardsAdapter.getSelectors((state) => state.stewards);

export const stewardsSelectors: EntitiesSelectors<any, any> = {
  selectList: stewardsAdapterSelectors.selectAll,
  selectById: stewardsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.stewards.init.status === "loaded",
};

export const stewardsReducer = stewardsSlice.reducer;

export const stewardsActions = {
  ...stewardsSlice.actions,
  ...asyncActions,
};
