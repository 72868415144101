import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    list: [],
    init: false,
    publicProjectsList: [],
    initPublicProjects: false,
  },
  reducers: {
    updateInList: (state, action) => {
      state.list = [action.payload, ...state.list.filter((i) => i._id !== action.payload._id)];
    },
    initList: (state, action) => {
      state.init = !!action.payload;
      state.list = action.payload || [];
    },
    initPublicProjectsList: (state, action) => {
      state.initPublicProjects = !!action.payload;
      state.publicProjectsList = action.payload || [];
    },
    clean: (state, action) => {
      state.list = [];
      state.init = false;
      state.publicProjectsList = [];
      state.initPublicProjects = false;
    },
  },
});

const asyncActions = {
  loadList:
    ({displayAllPublicProjects} = {}) =>
    async (dispatch, getState) => {
      const state = getState();

      const init = displayAllPublicProjects
        ? state.projects.initPublicProjects
        : state.projects.init;

      if (!init) {
        await loadListFromBackend(
          displayAllPublicProjects ? "projects/public" : "projects",
          undefined,
          state.projects.init,
          null, // No need because no existing function initContext
          (data) =>
            dispatch(
              displayAllPublicProjects
                ? projectsActions.initPublicProjectsList(data)
                : projectsActions.initList(data)
            )
        );
      }
    },
  cleanProjectList: () => async (dispatch, getState) => {
    await dispatch(projectsActions.initList(false));
  },
};

export const projectsSelectors = {
  selectList: (state) => state.projects.list,
  selectPublicList: (state) => state.projects.publicProjectsList,
};

export const projectsReducer = projectsSlice.reducer;

export const projectsActions = {
  ...projectsSlice.actions,
  ...asyncActions,
};
